import React, { PropsWithChildren } from 'react';

declare global {
  interface Window {
    dataLayer: Record<string, unknown>[];
  }
}

type Trigger = keyof Omit<React.DOMAttributes<HTMLElement>, 'children' | 'dangerouslySetInnerHTML'>;

interface EventArgs {
  ga4_event_name?: string;
  e_label: string;
  e_action: string;
}

type Props = PropsWithChildren<EventArgs & { trigger?: Trigger }>;

/**
 * GTMEvent is a React functional component that wraps its child component
 * and attaches a Google Tag Manager (GTM) event to it.
 *
 * @param {Props} props - The properties passed to the component.
 * @param {React.ReactNode} props.children - The child component to which the GTM event will be attached.
 * @param {string} [props.trigger='onClick'] - The event type that will trigger the GTM event. Defaults to 'onClick'.
 * @param {object} props.gaProps - Additional properties to be included in the GTM event.
 *
 * @throws {Error} Throws an error if the child component is not a valid React element.
 *
 * @returns {React.ReactElement} A cloned React element with the GTM event attached to it.
 */
const GTMEvent: React.FC<Props> = ({ children, trigger = 'onClick', ...gaProps }) => {
  if (!React.isValidElement(children)) {
    throw new Error('GTMEvent must have a valid child');
  }

  return React.cloneElement(children, {
    ...children.props,
    [trigger]: (ogParams: React.SyntheticEvent) => {
      window.dataLayer = window.dataLayer || [];

      window.dataLayer.push({
        event: 'ga4_event',
        ga4_event_name: 'Painel_Nova_VirtUOL',
        ...gaProps
      });

      const ogFunc = children.props[trigger] as React.EventHandler<React.SyntheticEvent>;

      if (ogFunc) ogFunc(ogParams);
    }
  });
};

/**
 * Pushes a custom event to the Google Tag Manager dataLayer.
 *
 * @param {EventArgs} data - The event data to be pushed to the dataLayer.
 * @example
 * customDataLayer({
 *   e_label: 'Frete',
 *   e_action: 'Botão-Enviar'
 * });
 */
export const customDataLayer = (data: EventArgs) => {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: 'ga4_event',
    ga4_event_name: 'Painel_Nova_VirtUOL',
    ...data
  });
};

export default GTMEvent;
