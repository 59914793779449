/* eslint-disable prefer-const */
/* eslint-disable prefer-rest-params */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck

export class NPS {
  private createScript() {
    (function (f, b, g) {
      const dayToMilisseconds = 86400000;
      const dateDifInMilisseconds = 90 * dayToMilisseconds;
      const firstDate = new Date(window.uolHostVariables.user.firstAcquisitionDate).getTime();
      const interval = Date.now() - firstDate;

      if (interval >= dateDifInMilisseconds) {
        const NPS_TRACKED_PRODUCTS_MAP = {
          Domínios: 'DOMINIOS',
          Hospedagem: 'HOSPEDAGEM',
          'Certificado SSL': 'CERTIFICADOS',
          'Criador de Sites': 'CRIADOR DE SITES',
          'Vende Fácil': 'VENDE FACIL',
          'E-mail Marketing': 'E-MAIL MARKETING',
          'UOL Anúncios': 'ANUNCIOS',
          'Loja Virtual': 'LOJA VIRTUOL',
          Conecte: 'CONECTE',
          'Office 365': 'MICROSOFT OFFICE 365',
          'Cloud Computing': 'CLOUD COMPUTING',
          'Google Workspace': 'GOOGLE WORKSPACE',
          'Google Ads': 'GOOGLE ADS',
          'Facebook Ads': 'FACEBOOK ADS',
          'E-mail Profissional': 'E-MAIL PROFISSIONAL'
        };

        const authorizedProducts = window.uolHostVariables.user.authorizedProducts;
        const npsAuthorizedProducts = [];
        let productName = '';

        for (let i = 0; i < authorizedProducts.length; i++) {
          productName = NPS_TRACKED_PRODUCTS_MAP[authorizedProducts[i]];
          if (productName) {
            npsAuthorizedProducts.push(productName);
          }
        }

        const a = {
          key: '04f448d64535f5e0',
          campaign: '4b6e15b5',
          email: window.uolHostVariables.user.email,
          tags: {
            PRODUTOS: npsAuthorizedProducts.toString()
          },
          salute: 'Olá, tudo bem?',
          thanks: 'Agradecemos sua participação!'
        };
        let e;
        const c = f.getElementsByTagName(b)[0];
        if (f.getElementById(g)) {
          return;
        }
        e = f.createElement(b);
        e.id = g;
        e.src = 'https://cdn.tracksale.co/tracksale-js/tracksale.js';
        e.type = 'text/javascript';
        e.async = true;
        e.onload = e.onreadystatechange = function () {
          const h = this.readyState;
          if (h && h != 'complete' && h != 'loaded') {
            return;
          }
          try {
            const d = new Tracksale();
            d.init(a);
          } catch (i) {}
        };
        c.parentNode.insertBefore(e, c);
      }
    })(document, 'script', 'tracksale-js');
  }

  constructor() {
    this.createScript();
  }
}
