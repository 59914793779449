import ClientBuilder from './ClientBuilder';

const builder = new ClientBuilder();

const BASE_URL = process.env.REACT_APP_API_URL ?? 'https://api.lojavirtuol.uol.com.br';

const AxiosRequest = builder.withBaseURL(BASE_URL).withDefaults().build();

export class MSRequestURL extends URL {
  constructor(path: string) {
    super(path, BASE_URL);
  }
}

export default AxiosRequest;
