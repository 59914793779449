import S from './ContextMenus.component.style.module.scss';
import IconAtendimento from 'Components/General/IconAtendimento/IconAtendimento.component';
import GTMEvent from 'Utils/GTM/event';

const HeaderNav = () => {
  return (
    <>
      <nav className={S['header-nav']}>
        <GTMEvent e_label="Header" e_action="Botão-Atendimento">
          <a
            href="https://meupainelhost.uol.com.br/atendimento?utm_source=painel_loja&utm_medium=painel_loja&utm_campaign=cabecalho_painel&utm_id=cabecalho_painel"
            target="_blank"
            rel="noreferrer"
            title="Acesse na nossa Central de Atendimento"
            id="a-atendimento"
          >
            <IconAtendimento />
            <span>Atendimento</span>
          </a>
        </GTMEvent>
      </nav>
    </>
  );
};

export default HeaderNav;
