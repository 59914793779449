import { Outlet, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

function Tracking() {
  const location = useLocation();
  useEffect(() => {
    window.hj('stateChange', window.location.pathname);
  }, [location]);
  return <Outlet />;
}

export default Tracking;
