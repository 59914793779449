import HTTP from 'Types/HTTP';
import { ISideMenu, hasChild } from 'Types/ISideMenu';
import { TOnboarding } from 'Types/TOnboarding';
import MSRequest, { UseQueryCallback } from 'Utils/MSRequest';
import { ICompanyDomain } from 'Types/ICompanyDomain';
import { GetCompanyCanUseFlexiDesignRequest } from 'Services/ThemeListRequests';

export const sideMenuRequest = MSRequest<ISideMenu[]>(HTTP.GET, '/company/menu', {
  responseDataTransform: (arr) =>
    [...arr]
      .sort((a, b) => a.ordem - b.ordem)
      .map((item) => {
        if (hasChild(item)) {
          return {
            ...item,
            childMenuItems: item.childMenuItems.sort((a, b) => a.ordem - b.ordem)
          };
        }
        return item;
      })
});

export const sideMenuRequestWithNewFeatureFlag: UseQueryCallback<ISideMenu[]> = async (...args) => {
  const menuDataProm = sideMenuRequest(...args);
  const canUseOpenFrontProm = GetCompanyCanUseFlexiDesignRequest(...args).catch(() => false);

  const [menuData, canUseOpenFront] = await Promise.all([menuDataProm, canUseOpenFrontProm]);

  if (!canUseOpenFront) {
    return menuData;
  }

  const customizeMenu = menuData.find((item) => item.idAbaPainelJetPai === '5');

  if (!customizeMenu || !('childMenuItems' in customizeMenu)) {
    return menuData;
  }

  const layoutAndThemes = customizeMenu.childMenuItems.find(
    (item) => item.name === 'Layout e Temas'
  );

  if (!layoutAndThemes) {
    return menuData;
  }

  layoutAndThemes.newFeature = true;
  return menuData;
};

export const onboardingRequest = MSRequest<TOnboarding>(HTTP.GET, '/company/onboarding/list');

export const companyDomainRequest = MSRequest<ICompanyDomain>(HTTP.GET, '/company/dominioLoja', {
  responseDataTransform: (data) => ({
    ...data,
    customDomain: !data.customDomain ? data.customDomain : data.customDomain.replace(/\/$/, '')
  })
});
