import HTTP from 'Types/HTTP';
import { MSRequestURL } from 'Utils/AxiosRequest';
import { TApplyThemeResponse, ThemeKind, TTheme, TThemeConfig } from 'Types/TTheme';
import MSRequest, { MSRequestMutation } from 'Utils/MSRequest';

export const GetThemeListRequest = MSRequest<TThemeConfig>(
  HTTP.GET,
  '/theme/Theme/ListThemesByCompany'
);

export const GetCompanyCanUseFlexiDesignRequest = MSRequest<boolean>(
  HTTP.GET,
  '/theme/Theme/CheckCompanyFlexDesign'
);

export const ApplyThemeRequest = (payload: {
  idTema: number;
  tipo: ThemeKind;
  republish: boolean;
  currentTheme?: TTheme;
}) => {
  return MSRequestMutation<TApplyThemeResponse>(HTTP.POST, '/theme/Theme/ActivateTheme', {
    body: payload,
    rawResponse: true
  });
};

export const CreateThemeRequest = (baseThemeId?: number) => {
  const url = new MSRequestURL('/theme/Theme/InsertTheme');

  if (baseThemeId && Number.isInteger(baseThemeId)) {
    url.searchParams.append('baseTheme', baseThemeId.toString());
  }

  return MSRequestMutation(HTTP.POST, url.toString());
};

export const GetThemeQueueStatusRequest = MSRequest<boolean>(
  HTTP.GET,
  '/theme/Theme/CheckQueueTheme'
);

export const GetAcceptanceTermsStatusRequest = MSRequest<boolean>(
  HTTP.GET,
  '/theme/Theme/GetAcceptanceTerm'
);

export const AcceptAcceptanceTermsRequest = () =>
  MSRequestMutation(HTTP.POST, '/theme/Theme/AcceptThemeTerm');
