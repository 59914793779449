import Router from 'Router/Router';
import Hotjar from 'Utils/Hotjar';
import GTM from 'Utils/GTM';
import Clarity from 'Utils/Clarity';
import Helper from 'Utils/Helper';
import * as ReactDOMClient from 'react-dom/client';
import 'App/Global/Style/global.scss';
import '@zero/react/dist/styles.css';
import '@zero/lab/dist/styles.css';
import { start } from 'single-spa';
import 'systemjs';

start({
  urlRerouteOnly: true
});

if (process.env.NODE_ENV === 'production') {
  new Hotjar(3392942);
  new Clarity('igo5jl6ikp');
  new Helper('ivYoHNaL');
  new GTM('GTM-MXZKQV3Z');
} else {
  window.hj = (...args: string[]) => {
    console.log('Hotjar', ...args);
  };
}

const root = ReactDOMClient.createRoot(document.getElementById('root') as HTMLElement);

root.render(<Router />);
