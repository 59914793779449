import S from './SideContent.component.style.module.scss';
import SideContentTable from './SideContentTable.component';
import { getStartDate } from 'Services/ReportRequests';
import { customDataLayer } from 'Utils/GTM/event';
import { PropsWithChildren, useState } from 'react';
import { Icon, Select } from 'uol-host-react-ui';

type Props = PropsWithChildren<
  React.HTMLAttributes<HTMLDivElement> & {
    name: string;
    sideContentIsOpen: boolean;
    toggleSideContentIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  }
>;

type OptionType = {
  isSelected: boolean;
  label: string;
  value: string;
};

const selectOptions = [
  {
    isSelected: false,
    label: 'Uma Semana',
    value: getStartDate(6, 'day')
  },
  {
    isSelected: true,
    label: 'Um Mês',
    value: getStartDate(30, 'day')
  },
  {
    isSelected: false,
    label: 'Três Meses',
    value: getStartDate(2)
  },
  {
    isSelected: false,
    label: 'Seis Meses',
    value: getStartDate(5)
  },
  {
    isSelected: false,
    label: 'Um Ano',
    value: getStartDate(11)
  }
];

const SideContent: React.FC<Props> = (props) => {
  const { name, sideContentIsOpen, toggleSideContentIsOpen, className, children, ...data } = props;
  const [selectedVal, setSelectedVal] = useState(selectOptions[1].value);
  const [groupBy, setGroupBy] = useState<'Day' | 'Week' | 'Month' | 'Year'>('Day');
  const [isLoading, setIsLoading] = useState(true);

  const handleSelect = (selectOption: OptionType) => {
    if (selectedVal !== selectOption.value) {
      setIsLoading(true);
      setSelectedVal(selectOption.value);
      customDataLayer({
        e_label: 'Home-Drawer',
        e_action: `Select-Período_${selectOption.label.replace(' ', '_')}`
      });
      if (selectOption.label === 'Uma Semana' || selectOption.label === 'Um Mês') {
        setGroupBy('Day');
      } else {
        setGroupBy('Month');
      }
    }
  };

  const handleClick = () => {
    toggleSideContentIsOpen(false);
    setIsLoading(true);
    window.scrollTo(0, 0);
  };

  const handleLoading = (bool: boolean) => {
    setIsLoading(bool);
  };

  return (
    <div
      {...data}
      className={`${S.container} ${className || ''} ${sideContentIsOpen && S.open}`}
      data-testid="sideContent"
    >
      <div className={S.backdrop} onClick={handleClick}></div>
      <div className={S['side-content']}>
        <h3 className={S['content-title']}>{name.toUpperCase()}</h3>
        <div className={S['content-header']}>
          <h4 className={S['header-title']}>Visualização por período</h4>
          <Select
            onSelect={(selectOption) => handleSelect(selectOption)}
            options={selectOptions}
            variant="link"
            label="Período"
            disabled={isLoading}
            data-testid="select"
          />
        </div>
        <div className={S['content-body']}>
          {sideContentIsOpen && (
            <SideContentTable
              isLoading={isLoading}
              handleLoading={handleLoading}
              period={selectedVal}
              groupBy={groupBy}
            />
          )}
        </div>
        <button
          type="button"
          className={S['btn-close']}
          onClick={handleClick}
          data-testid="btnClose"
        >
          <Icon type="close" variant="link" />
        </button>
      </div>
    </div>
  );
};

export default SideContent;
